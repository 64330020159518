<template>
    <div class="c-social-sharing">
        <p v-if="text" class="o-text--caption u-text--gray u-spacer--2">
            {{ text }}
        </p>
        <div class="c-social-sharing__links">
            <div
                v-if="canShareInstagram"
                v-tab-focus="() => shareArticle('instagram')"
                class="c-social-sharing__link"
            >
                <BaseIcon
                    icon="social--instagram"
                    size="24px"
                    color="primary"
                />
            </div>
            <div
                v-tab-focus="() => shareArticle('linkedin')"
                class="c-social-sharing__link"
            >
                <BaseIcon
                    icon="social--linkedin"
                    size="24px"
                    color="primary"
                />
            </div>
            <div
                v-tab-focus="() => shareArticle('pinterest')"
                class="c-social-sharing__link"
            >
                <BaseIcon
                    icon="social--pinterest"
                    size="24px"
                    color="primary"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { SOCIAL_SHARE } from '~coreModules/core/js/global-event-constants';

export default {
    name: 'SocialSharing',
    props: {
        text: {
            type: String,
            default: null,
        },
        shareText: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            canShareInstagram: false,
        };
    },
    computed: {
        getPageUrl() {
            return window.location.origin + this.$route.path;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.canShareInstagram = !!window?.navigator?.userAgent.includes('iPhone');
        });
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        shareArticle(platform) {
            let encodedUrl =
                `${this.getPageUrl}?utm_source=${platform}&utm_medium=social-share&utm_content=news-center`;
            encodedUrl = encodeURIComponent(encodedUrl);
            let shareUrl;
            let shareText;

            switch (platform) {
            case 'instagram':
                shareText = encodeURIComponent(`${this.shareText} `);
                shareUrl = `instagram://sharesheet?text=${shareText}${encodedUrl}`; // iOS deeplinking
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
                break;
            case 'pinterest':
                shareUrl = `http://pinterest.com/pin/create/button/?url=${encodedUrl}`;
                break;
            default:
                return;
            }

            window.open(shareUrl);

            this.trackGlobalEvent({
                type: SOCIAL_SHARE,
                label: platform,
            });
        },
    },
};
</script>

<style lang="scss">
    .c-social-sharing {
        text-align: center;

        &__links {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
        }

        &__link {
            cursor: pointer;
            padding: $nu-spacer-1;
        }
    }
</style>
