<template>
    <div v-if="shouldRender" class="c-article-tray">
        <div class="o-row o-row--nested">
            <EditorialTextModule
                class="c-article-tray__header o-extra-small--12"
                :editorialText="heading"
                :numberOfModules="1"
            />
        </div>
        <div class="c-article-tray__articles o-row o-row--nested">
            <ArticleCard
                v-for="(article, index) in articles"
                :key="`${article.slug}-${index}`"
                :articleSummary="article"
                class="c-article-tray__card o-extra-small--12 o-large--4"
            />
        </div>
        <div class="o-row o-row--nested">
            <div class="o-extra-small--12 u-text--center">
                <BaseLink :to="newsHomeRoute">
                    <BaseButton variant="secondary">
                        {{ ctaText }}
                    </BaseButton>
                </BaseLink>
            </div>
        </div>
    </div>
</template>

<script>

import { isEmpty } from 'lodash-es';

import { commonRouteNames } from '~modules/core/js/router-constants';

import EditorialTextModule from '~coreModules/contentful/components/EditorialTextModule.vue';
import ArticleCard from '~routes/news/components/ArticleCard.vue';
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

export default {
    name: 'ArticleTray',
    components: {
        ArticleCard,
        BaseButton,
        EditorialTextModule,
    },
    props: {
        heading: {
            type: Object,
            default: () => ({}),
        },
        articles: {
            type: Array,
            default: () => [],
        },
        ctaText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            newsHomeRoute: { name: commonRouteNames.news.base },
        };
    },
    computed: {
        shouldRender() {
            return !!(!isEmpty(this.heading) && !isEmpty(this.articles) && this.ctaText);
        },
    },
};
</script>

<style lang="scss">
    .c-article-tray {
        &__header {
            margin-bottom: $nu-spacer-4;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-5;
            }
        }

        &__articles {
            margin-bottom: $nu-spacer-4;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-6;
            }
        }

        &__card {
            margin-bottom: $nu-spacer-2;

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }
    }
</style>
