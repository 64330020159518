<template>
    <Layout class="c-news-detail" :flushWithHeader="true">
        <div v-if="!isEmpty(articleDetails)" class="o-row">
            <div class="o-extra-small--12 o-large--10 o-large--offset-1 u-spacer--12">
                <div class="c-news-detail__article-header u-text--center u-spacer--6">
                    <p class="o-text--label u-text--gray u-spacer--1">
                        <span>{{ articleTag }}</span>
                        <span class="c-news-detail__separator">|</span>
                        <ClientMounted>
                            <template #default="{ isClientMounted }">
                                <span v-if="isClientMounted">{{ articleDate }}</span>
                            </template>
                        </ClientMounted>
                    </p>
                    <h1 class="o-text--title-3 u-spacer--1pt5">
                        {{ articleTitle }}
                    </h1>
                    <p class="o-text--body-copy u-spacer--3">
                        {{ articleSubtitle }}
                    </p>

                    <SocialSharing :shareText="articlePreview" />
                </div>
                <ContentfulContentBlock
                    :contentRows="articleDetails.rows"
                    :contentTitle="articleDetails.title"
                />
                <SocialSharing
                    :text="shareLabel"
                    :shareText="articlePreview"
                />
            </div>
            <div class="o-extra-small--12">
                <ArticleTray
                    :articles="recentNews.articles"
                    :heading="recentNews.heading"
                    :ctaText="recentNews.ctaText"
                />
            </div>
        </div>
    </Layout>
</template>

<script>
import { get, isEmpty } from 'lodash-es';
import { mapGetters, mapState } from 'vuex';

import newsModule, {
    NEWS_MODULE_NAME,
    FETCH_ARTICLE_DETAILS,
    FETCH_RECENT_NEWS_ARTICLES,
} from '~routes/news/js/news-store';

import { NEWS_TRAY_PAGE_SIZE } from '~modules/contentful/js/contentful-constants';

import analyticsMixin from '~coreModules/analytics/js/analytics';
import newsDetailMetaMixin from '~routes/news/js/news-detail-meta';

import Layout from '~modules/core/components/layouts/Layout.vue';

import ClientMounted from '~coreModules/core/components/ui/ClientMounted.vue';
import ContentfulContentBlock from '~modules/contentful/components/ContentfulContentBlock.vue';
import SocialSharing from '~routes/news/components/SocialSharing.vue';
import ArticleTray from '~routes/news/components/ArticleTray.vue';

export default {
    name: 'NewsDetailContainer',
    components: {
        ArticleTray,
        ContentfulContentBlock,
        Layout,
        SocialSharing,
        ClientMounted,
    },
    mixins: [
        analyticsMixin,
        newsDetailMetaMixin,
    ],
    vuex: {
        moduleName: NEWS_MODULE_NAME,
        module: newsModule,
    },
    data() {
        return {
            isEmpty,
        };
    },
    computed: {
        ...mapGetters(NEWS_MODULE_NAME, [
            'moreArticlesForSlug',
        ]),
        ...mapState(NEWS_MODULE_NAME, [
            'articleDetails',
            'allNewsArticles',
        ]),
        articleDate() {
            const articleDate = get(this.articleDetails, 'articleSummary.articleDate');
            return this.$d(new Date(Date.parse(articleDate)), 'short');
        },
        articlePreview() {
            return get(this.articleDetails, 'articleSummary.articlePreview');
        },
        articleSlug() {
            return this.$route.params.slug;
        },
        articleSubtitle() {
            return get(this.articleDetails, 'articleSummary.articleSubtitle');
        },
        articleTag() {
            return get(this.articleDetails, 'articleSummary.tags.0');
        },
        articleTitle() {
            return get(this.articleDetails, 'articleSummary.articleTitle');
        },
        recentNews() {
            const heading = get(this.articleDetails, 'moreArticles.heading');
            const ctaText = get(this.articleDetails, 'moreArticles.cta');

            return {
                articles: this.moreArticlesForSlug(this.articleSlug),
                heading,
                ctaText,
            };
        },
        shareLabel() {
            return get(this.articleDetails, 'shareLabel');
        },
    },
    async fetchData({ store, route }) {
        const { slug } = route.params;
        const parallelRequests = [
            await store.dispatch(`${NEWS_MODULE_NAME}/${FETCH_ARTICLE_DETAILS}`, slug),
        ];
        const allNewsArticles = get(store, `state.${NEWS_MODULE_NAME}.allNewsArticles`);

        /* only fetch more news articles if we havent already */
        if (isEmpty(allNewsArticles)) {
            parallelRequests.push(
                await store.dispatch(`${NEWS_MODULE_NAME}/${FETCH_RECENT_NEWS_ARTICLES}`, NEWS_TRAY_PAGE_SIZE));
        }

        await Promise.all(parallelRequests);
    },
};
</script>

<style lang="scss">
    .c-news-detail {
        &__article-header {
            padding-top: $nu-spacer-6;

            @include breakpoint(medium) {
                padding-top: $nu-spacer-12;
            }
        }

        &__separator {
            color: $nu-gray--medium;
            margin: 0 $nu-spacer-0pt5;
        }
    }
</style>
